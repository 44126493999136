@import "variables";

html, body, #root {
  height: 100%;
}

.selection-content {
  margin: auto;
  padding: auto auto auto auto;
  margin-top: 2%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
